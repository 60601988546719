<template>
    <div class="table">
        <div class="thead" v-if="showHeader">
            <div class="td" v-for="col in columns" :key="col.name">{{col.text}}</div>
        </div>
        <div v-for="(row, i) in data" :key="i" class="tr" @click="trClick(row)">
            <div class="td" v-for="col in columns" :key="col.name" v-html="col.formatter? col.formatter(row[col.name], i) : row[col.name]"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        showHeader: {
            type: Boolean
        },
        columns: {
            type: Array,
            default: () => []
        },
        data: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        trClick(_row){
            this.$emit("rowClick", _row)
        }
    }
}
</script>

<style lang="scss" scoped>
    .table{
        display: table;
        font-size: 12px;
        color: #595959;
        width: 100%;
        .tr, .thead{
            display: table-row;
            .td{
                display: table-cell;
                padding: 10px 0;
            }
        }
        >.tr:nth-of-type(odd){
            background: rgb(247,247,247);
        }
        .thead{
            color: #8c8c8c;
            background: #f2f2f2;
        }
    }
</style>
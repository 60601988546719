<template>
    <div>
        <div class="flex-row" style="margin: 10px 0 5px;">
            <div class="filter-botton" :class="{active: tabIndex == 0}" @click="tabIndex = 0">调试参数合规率</div>
            <div class="filter-botton" :class="{active: tabIndex == 1}" @click="tabIndex = 1">其他参数</div>
        </div>

        <div v-show="tabIndex == 0">
            <div class="rect-box" v-for="(item,i) in complDetailList" :key="i">
                <div class="box-header">
                    <div class="middle-black-text">{{item.equipCode}}</div>
                    <div class="small-gray-text">调试完成时间：{{item.endTime || '-'}}</div>
                </div>
                <info-table :showHeader="true" :columns="complColumns" :data="item.paramInfos"></info-table>
            </div>
        </div>

        <div v-show="tabIndex == 1">
            <div class="rect-box" v-for="(item,i) in otherDetailList" :key="i">
                <div class="box-header">
                    <div class="middle-black-text">{{item.equipCode}}</div>
                    <div class="small-gray-text">调试完成时间：{{item.endTime || '-'}}</div>
                </div>
                <info-table :showHeader="true" :columns="otherColumns" :data="item.paramInfos"></info-table>
            </div>
        </div>
    </div>
</template>

<script>
import infoTable from '@/components/InfoTable'
import {getPortraitDetail} from '@/axios/device'
export default {
    components: {infoTable},
    data(){
        return {
            tabIndex: 0,                 
            complColumns: [{name: "paramName", text: "考核参数"}, {name: "ruleValue", text: "合规值"},  {name: "ruleName", text: "合规规则"}, 
                {name: "resultValue", text: "考核值"}, {name: "result", text: "考核结果", formatter: function(_v){
                    if(_v == "1"){
                        return `<span style="color: #f05252">不合格</span>`
                    }else{
                        return `<span style="color: #0e9f6e">合格</span>`
                    }
                }}],
            complDetailList: [],
            otherColumns: [{name: "paramName", text: "考核参数"}, {name: "ruleValue", text: "合规值"},  {name: "ruleName", text: "合规规则"}, 
                {name: "resultValue", text: "考核值"}, {name: "result", text: "考核结果", formatter: function(_v){
                    if(_v == "1"){
                        return `<span style="color: #f05252">不合格</span>`
                    }else{
                        return `<span style="color: #0e9f6e">合格</span>`
                    }
                }}],
            otherDetailList: []
        }
    },
    created(){        
        let _params = this.$route.query
        getPortraitDetail(Object.assign({},_params, {dateType: 0})).then(res => {
            this.complDetailList = res.content
        })
        getPortraitDetail(Object.assign({},_params, {dateType: 1})).then(res => {
            this.otherDetailList = res.content
        })
        
    }
}
</script>

<style lang="scss" scoped>
    .box-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5px 0 10px;
    }
</style>